import axios from 'axios';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import BarcodeReader from 'react-barcode-reader';
import { useTitle } from 'react-use';
import { Button, Container, Grid, Header, Icon, Input, Table } from 'semantic-ui-react';
import Footer from '../../shared-components/Footer';
import Navbar from '../../shared-components/Navbar';

function SuperPool() {
	const [term, setTerm] = useState('');
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	useTitle(`Laboratory | Super pool`);

	const getData = () => {
		axios
			.post('/api/superpool/pools', {
				superpool: term
			})
			.then(r => {
				setData(r.data);
			})
			.catch(e => console.log(e))
			.finally(() => setLoading(false));
	};

	return (
		<>
			<BarcodeReader
				onScan={res => {
					if (res) {
						getData();
					}
				}}
			/>
			<Navbar />
			<Container text className="mt-30 mb-30">
				<Grid stackable>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Header as="h2">Super Pool Scanner</Header>
							<p className="small quiet">Scan a super pool using a scanner or search for it using the search bar.</p>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Input
								fluid
								action
								loading={loading}
								placeholder="Super pool number.."
								value={term}
								onChange={e => setTerm(e.target.value)}
							>
								<input />
								<Button
									positive
									size="small"
									content="Search"
									loading={loading}
									onClick={() => {
										setLoading(true);
										setData([]);
										getData();
									}}
								/>
							</Input>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={1} textAlign="center">
						<Grid.Column>
							{data.length > 0 ? (
								<Table stackable textAlign="center" size="small" celled>
									<Table.Header>
										<Table.Row textAlign="right">
											<Table.HeaderCell colSpan="13">
												<Input
													name="filter"
													placeholder="Search pool or box..."
													iconPosition="left"
													size="small"
													value={search}
													icon={
														search ? <Icon link name="close" onClick={() => setSearch('')} /> : <Icon name="search" />
													}
													onChange={e => setSearch(e.target.value)}
												/>
											</Table.HeaderCell>
										</Table.Row>
										<Table.Row>
											<Table.HeaderCell>Pool</Table.HeaderCell>
											<Table.HeaderCell>Box</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
										{matchSorter(data, search, {
											keys: ['pool, box']
										}).map(item => {
											return (
												<Table.Row key={item.pool}>
													<Table.Cell>
														<Header as="h5">
															<Header.Content>{item.pool}</Header.Content>
														</Header>
													</Table.Cell>
													<Table.Cell>{item.box}</Table.Cell>
												</Table.Row>
											);
										})}
									</Table.Body>
									<Table.Footer fullWidth>
										<Table.Row>
											<Table.HeaderCell colSpan="2" textAlign="left">
												<Header size="tiny">
													<Header.Content>
														Showing{' '}
														{
															matchSorter(data, search, {
																keys: ['pool', 'superpool']
															}).length
														}{' '}
														of {data.length}
													</Header.Content>
												</Header>
											</Table.HeaderCell>
										</Table.Row>
									</Table.Footer>
								</Table>
							) : (
								<Header as="h3" disabled>
									NO DATA TO DISPLAY
								</Header>
							)}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
			<Footer />
		</>
	);
}

export default SuperPool;
